import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br, PrevNext, Text } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v7' next='/dz/v9' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第八卷-阎罗王众赞叹品"
    }}>{`第八卷 阎罗王众赞叹品`}</h3>
    <Text mdxType="Text">
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wéi" zi="围" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="nèi" zi="内" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="yì" zi="诣" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="dào" zi="到" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="wèi" zi="谓" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="zhēnɡ" zi="诤" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="hǔ" zi="虎" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="xuè" zi="血" mdxType="Zu" />
  <Zu yin="hǔ" zi="虎" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="chì" zi="赤" mdxType="Zu" />
  <Zu yin="hǔ" zi="虎" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sàn" zi="散" mdxType="Zu" />
  <Zu yin="yānɡ" zi="殃" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="fēi" zi="飞" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="diàn" zi="电" mdxType="Zu" />
  <Zu yin="ɡuānɡ" zi="光" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="lánɡ" zi="狼" mdxType="Zu" />
  <Zu yin="yá" zi="牙" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="yǎn" zi="眼" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dàn" zi="啖" mdxType="Zu" />
  <Zu yin="shòu" zi="兽" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="fù" zi="负" mdxType="Zu" />
  <Zu yin="shí" zi="石" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="hào" zi="耗" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="huò" zi="祸" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="cái" zi="财" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="chù" zi="畜" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="qín" zi="禽" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="shòu" zi="兽" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="mèi" zi="魅" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="chǎn" zi="产" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="jí" zi="疾" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="mù" zi="目" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="mù" zi="目" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="mù" zi="目" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="qí" zi="祁" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="qí" zi="祁" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="qí" zi="祁" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="qí" zi="祁" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="nuó" zi="那" mdxType="Zu" />
  <Zu yin="zhā" zi="吒" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="nuó" zi="那" mdxType="Zu" />
  <Zu yin="zhā" zi="吒" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="jū" zi="居" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="zhí" zi="执" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="yì" zi="诣" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="miàn" zi="面" mdxType="Zu" />
  <Zu yin="lì" zi="立" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hú" zi="胡" mdxType="Zu" />
  <Zu yin="ɡuì" zi="跪" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="yì" zi="诣" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="yí" zi="疑" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="敢" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="xuān" zi="宣" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="zì" zi="恣" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="shì" zi="视" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="ɡuān" zi="观" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="cí" zi="辞" mdxType="Zu" />
  <Zu yin="pí" zi="疲" mdxType="Zu" />
  <Zu yin="juàn" zi="倦" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rán" zi="然" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="jiǔ" zi="久" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="jiān" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="jì" zi="既" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yún" zi="云" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="yī" zi="依" mdxType="Zu" />
  <Zu yin="zhǐ" zi="止" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="？" zi="？" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jiě" zi="解" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="nán" zi="南" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="ɡānɡ" zi="刚" mdxType="Zu" />
  <Zu yin="qiánɡ" zi="强" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nán" zi="难" mdxType="Zu" />
  <Zu yin="tiáo" zi="调" mdxType="Zu" />
  <Zu yin="nán" zi="难" mdxType="Zu" />
  <Zu yin="fú" zi="伏" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tóu" zi="头" mdxType="Zu" />
  <Zu yin="tóu" zi="头" mdxType="Zu" />
  <Zu yin="jiù" zi="救" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zǎo" zi="早" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="qiǎn" zi="遣" mdxType="Zu" />
  <Zu yin="wù" zi="悟" mdxType="Zu" />
  <Zu yin="sù" zi="宿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="jié" zi="结" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="xí" zi="习" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xuán" zi="旋" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="xuán" zi="旋" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="láo" zi="劳" mdxType="Zu" />
  <Zu yin="sī" zi="斯" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="jiǔ" zi="久" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="dù" zi="度" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="pì" zi="譬" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="jiā" zi="家" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wù" zi="误" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="hǔ" zi="虎" mdxType="Zu" />
  <Zu yin="lánɡ" zi="狼" mdxType="Zu" />
  <Zu yin="shī" zi="狮" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="yuán" zi="蚖" mdxType="Zu" />
  <Zu yin="shé" zi="蛇" mdxType="Zu" />
  <Zu yin="fù" zi="蝮" mdxType="Zu" />
  <Zu yin="xiē" zi="蝎" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xū" zi="须" mdxType="Zu" />
  <Zu yin="yú" zi="臾" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="jiān" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="zāo" zi="遭" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="shí" zi="识" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="jiě" zi="解" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="shù" zi="术" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="jìn" zi="禁" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hū" zi="忽" mdxType="Zu" />
  <Zu yin="fénɡ" zi="逢" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="jìn" zi="进" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="yù" zi="语" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="duō" zi="咄" mdxType="Zu" />
  <Zu yin="zāi" zi="哉" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="yì" zi="异" mdxType="Zu" />
  <Zu yin="shù" zi="术" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="zhì" zi="制" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="hū" zi="忽" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yǔ" zi="语" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="bù" zi="步" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiú" zi="求" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="shí" zi="识" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="xié" zi="携" mdxType="Zu" />
  <Zu yin="jiē" zi="接" mdxType="Zu" />
  <Zu yin="shǒu" zi="手" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐn" zi="引" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="miǎn" zi="免" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="hǎo" zi="好" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="yù" zi="语" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="duō" zi="咄" mdxType="Zu" />
  <Zu yin="zāi" zi="哉" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="hòu" zi="后" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wù" zi="勿" mdxType="Zu" />
  <Zu yin="lǚ" zi="履" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zú" zi="卒" mdxType="Zu" />
  <Zu yin="nán" zi="难" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="sǔn" zi="损" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="shí" zi="识" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="qīn" zi="亲" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sànɡ" zi="丧" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="xìnɡ" zi="性" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiù" zi="救" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="lí" zi="离" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="zài" zi="再" mdxType="Zu" />
  <Zu yin="lì" zi="历" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wù" zi="误" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="shí" zi="识" mdxType="Zu" />
  <Zu yin="yǐn" zi="引" mdxType="Zu" />
  <Zu yin="jiē" zi="接" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="chū" zi="出" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fénɡ" zi="逢" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="quàn" zi="劝" mdxType="Zu" />
  <Zu yin="mò" zi="莫" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="yīn" zi="因" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="zài" zi="再" mdxType="Zu" />
  <Zu yin="lǚ" zi="履" mdxType="Zu" />
  <Zu yin="jiàn" zi="践" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yóu" zi="犹" mdxType="Zu" />
  <Zu yin="shànɡ" zi="尚" mdxType="Zu" />
  <Zu yin="mí" zi="迷" mdxType="Zu" />
  <Zu yin="wù" zi="误" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="jué" zi="觉" mdxType="Zu" />
  <Zu yin="jiù" zi="旧" mdxType="Zu" />
  <Zu yin="cénɡ" zi="曾" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="xiǎn" zi="险" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhì" zi="致" mdxType="Zu" />
  <Zu yin="shī" zi="失" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="xuán" zi="旋" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="zài" zi="再" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="jié" zi="结" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="chǔ" zi="处" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dú" zi="毒" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="sǔn" zi="损" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="tónɡ" zi="同" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rán" zi="然" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="yóu" zi="游" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="jiɑ" zi="家" mdxType="Zu" />
  <Zu yin="tínɡ" zi="庭" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="chénɡ" zi="城" mdxType="Zu" />
  <Zu yin="yì" zi="邑" mdxType="Zu" />
  <Zu yin="jù" zi="聚" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhuānɡ" zi="庄" mdxType="Zu" />
  <Zu yin="yuán" zi="园" mdxType="Zu" />
  <Zu yin="fánɡ" zi="房" mdxType="Zu" />
  <Zu yin="shè" zi="舍" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="máo" zi="毛" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="xuán" zi="悬" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="fān" zi="幡" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="ɡài" zi="盖" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="香" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="huā" zi="华" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="dú" zi="读" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="香" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jù" zi="句" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jì" zi="偈" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="qù" zi="去" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="chì" zi="敕" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="tǔ" zi="土" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="fēn" zi="分" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="wèi" zi="卫" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="hènɡ" zi="横" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="bìnɡ" zi="病" mdxType="Zu" />
  <Zu yin="hènɡ" zi="横" mdxType="Zu" />
  <Zu yin="bìnɡ" zi="病" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="yì" zi="意" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jìn" zi="近" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="shè" zi="舍" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="chù" zi="处" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="rù" zi="入" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="zāi" zi="哉" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="zāi" zi="哉" mdxType="Zu" />
  <Zu yin="！" zi="！" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="yǔ" zi="与" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yōnɡ" zi="拥" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="fàn" zi="梵" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="dì" zi="帝" mdxType="Zu" />
  <Zu yin="shì" zi="释" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="wèi" zi="卫" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yǔ" zi="语" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jiē" zi="皆" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shèn" zi="甚" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="yì" zi="意" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="致" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="chū" zi="初" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zēnɡ" zi="增" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="shè" zi="舍" mdxType="Zu" />
  <Zu yin="zhái" zi="宅" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="tǔ" zi="土" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="huān" zi="欢" mdxType="Zu" />
  <Zu yin="xǐ" zi="喜" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yōnɡ" zi="拥" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shèn" zi="慎" mdxType="Zu" />
  <Zu yin="wù" zi="勿" mdxType="Zu" />
  <Zu yin="shā" zi="杀" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiān" zi="鲜" mdxType="Zu" />
  <Zu yin="wèi" zi="味" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="jǐ" zi="给" mdxType="Zu" />
  <Zu yin="chǎn" zi="产" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="ɡuǎnɡ" zi="广" mdxType="Zu" />
  <Zu yin="jù" zi="聚" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="jiǔ" zi="酒" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="ròu" zi="肉" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡē" zi="歌" mdxType="Zu" />
  <Zu yin="yuè" zi="乐" mdxType="Zu" />
  <Zu yin="xián" zi="弦" mdxType="Zu" />
  <Zu yin="ɡuǎn" zi="管" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="chǎn" zi="产" mdxType="Zu" />
  <Zu yin="nàn" zi="难" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="wǎnɡ" zi="魍" mdxType="Zu" />
  <Zu yin="liǎnɡ" zi="魉" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="精" mdxType="Zu" />
  <Zu yin="mèi" zi="魅" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="xīnɡ" zi="腥" mdxType="Zu" />
  <Zu yin="xuè" zi="血" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="zǎo" zi="早" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shě" zi="舍" mdxType="Zu" />
  <Zu yin="zhái" zi="宅" mdxType="Zu" />
  <Zu yin="tǔ" zi="土" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="línɡ" zi="灵" mdxType="Zu" />
  <Zu yin="qí" zi="祇" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hè" zi="荷" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="biàn" zi="便" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="shè" zi="设" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dá" zi="答" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="tǔ" zi="土" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fān" zi="翻" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="shā" zi="杀" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="聚" mdxType="Zu" />
  <Zu yin="jí" zi="集" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fàn" zi="犯" mdxType="Zu" />
  <Zu yin="yānɡ" zi="殃" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="sǔn" zi="损" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="zēnɡ" zi="增" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="xíng" zi="行" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="biàn" zi="变" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="fù" zi="父" mdxType="Zu" />
  <Zu yin="mǔ" zi="母" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐn" zi="引" mdxType="Zu" />
  <Zu yin="jiē" zi="接" mdxType="Zu" />
  <Zu yin="wánɡ" zi="亡" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="běn" zi="本" mdxType="Zu" />
  <Zu yin="zào" zi="造" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="shí" zi="识" mdxType="Zu" />
  <Zu yin="hūn" zi="昏" mdxType="Zu" />
  <Zu yin="mèi" zi="昧" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="biàn" zi="辨" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="yǎn" zi="眼" mdxType="Zu" />
  <Zu yin="ěr" zi="耳" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="xū" zi="须" mdxType="Zu" />
  <Zu yin="shè" zi="设" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="dú" zi="读" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="," zi="," mdxType="Zu" />
  <Zu yin="náng" zi="能" mdxType="Zu" />
  <Zu yin="lìng" zi="令" mdxType="Zu" />
  <Zu yin="wánɡ" zi="亡" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="lí" zi="离" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="mó" zi="魔" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="xī" zi="悉" mdxType="Zu" />
  <Zu yin="jiē" zi="皆" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="sàn" zi="散" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="lín" zi="临" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="终" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chénɡ" zi="乘" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jù" zi="句" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jì" zi="偈" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="ɡuān" zi="观" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bèi" zi="辈" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chú" zi="除" mdxType="Zu" />
  <Zu yin="wǔ" zi="五" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="shā" zi="杀" mdxType="Zu" />
  <Zu yin="hài" zi="害" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="qù" zi="趣" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xún" zi="寻" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="mò" zi="莫" mdxType="Zu" />
  <Zu yin="tuì" zi="退" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zǒnɡ" zi="总" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="lǜ" zi="虑" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="yōnɡ" zi="拥" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="俱" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xìn" zi="信" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="yǔ" zi="语" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="xiè" zi="解" mdxType="Zu" />
  <Zu yin="tuō" zi="脱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="cénɡ" zi="曾" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yōnɡ" zi="拥" mdxType="Zu" />
  <Zu yin="hù" zi="护" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="shì" zi="士" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shí" zi="实" mdxType="Zu" />
  <Zu yin="fēi" zi="非" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="yě" zi="也" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="què" zi="却" mdxType="Zu" />
  <Zu yin="hòu" zi="后" mdxType="Zu" />
  <Zu yin="ɡuò" zi="过" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="相" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="净" mdxType="Zu" />
  <Zu yin="zhù" zi="住" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="shòu" zi="寿" mdxType="Zu" />
  <Zu yin="mìnɡ" zi="命" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="jì" zi="计" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǐ" zi="鬼" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="duó" zi="度" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="yì" zi="亦" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="xiàn" zi="限" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v7' next='/dz/v9' mdxType="PrevNext" /> 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      